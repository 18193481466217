@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/animation";

@import "frameworks/customBootstrap";

@import "vendor/bootstrap-select";
@import "vendor/slick-carousel";
@import "vendor/icomoon";

@import "base/color";
@import "base/fonts";
@import "base/heading";
@import "base/paragraph";
@import "base/reset";
@import "base/typography";


@import "components/buttons";
@import "components/breadcrumbs";
@import "components/checkbox";
@import "components/dropdown";
@import "components/form";
@import "components/modal";
@import "components/navbar";
@import "components/section";

/*
@import "components/accordion";
@import "components/list-group";

@import "components/navigation";
@import "components/pagination";
@import "components/radio";
@import "components/select";
@import "components/table";
*/


@import "widgets/banner";
@import "widgets/episerver-form";
@import "widgets/image-gallery-slider";
@import "widgets/navigation-dropdown-mobile";
@import "widgets/related-plans";
@import "widgets/search-panel";
@import "widgets/sidepanel";
@import "widgets/spinner-overlay";
@import "widgets/stickysidebar";
@import "widgets/tile";

/*
@import "widgets/category-thumbnail-tile";
@import "widgets/getting-started-tile";
@import "widgets/region-languages";
@import "widgets/social-media";
@import "widgets/submit-and-register";
@import "widgets/timeline";
*/



@import "layout/content";
@import "layout/footer";
@import "layout/grid";

/*
@import "layout/header";
*/


@import "pages/home";
@import "pages/lesson-plans";
@import "pages/newsevents";
@import "pages/resource-library";
/*
@import "pages/about";
@import "pages/faq";
*/



@import "vendor/swiper";


/* order is important */

/*######################################################
  banner
#######################################################*/
.banner {
    @include break-sm {
        margin-bottom: 0;
    }
}

.banner-inner {
    color: #fff;
    position: relative;

    p {
        margin: 0;
    }
}

.banner-title {
    font-family: $font-primary-bold;
    font-weight: bold;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    //text-shadow: 0px 2px 4px rgba(0, 0, 0, .6);

    @include break-sm {
        font-size: 4rem;
    }

    @include break-xs {
        font-size: 3.4rem;
    }
}

.banner-subtitle {
    font-family: $font-secondary;
    line-height: 36px !important;
    margin: 40px auto;
    max-width: 736px;
}

/* video */
.banner-video-media {
    position: relative;
    overflow: hidden;
    width:100vw;
    height:100vh;
  /*
    @include break-sm(true) {
        max-height:558px;
    }

    @include break-sm {   
        height: 490px;
    }


    .banner-carousel & {
      
        @include break-lg (true) {
            height: 558px;
        }

        @include break-lg {
            height: 480px;
        }

        @include break-md {
            height: 400px;
        }

        @include break-sm {
            height: 270px;
            overflow: hidden;
        }

        @include break-xs {
            height: 200px;
        }

    }  */
}

.banner-video-frame {
    position:absolute;
    height:100%;
    width:200%;
    left:-50%;
    z-index: 0;

    @include break-xs {
        left: -100%;
    }
}

.banner-video {
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    min-height:100%;
    min-width:50%;


    .banner-carousel & {

        @include break-xs {
            display: block;
            top: 6px;
            left: 0;
            min-width: 100%;
            min-height: auto;
            width: 150%;
            height: 240px;
            -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
            //pointer-events: none;
            overflow-y: hidden;
            vertical-align: top;
        }
    }
    
}

.banner-video-button {
    @include border-radius(50%);
    @include box-shadow(0 0px 14px -4px rgba(0, 0, 0, 0.175));
    background-color: #fff;
    color: $gray-dark;
    cursor: pointer;
    display: block;
    height: 102px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -61px;
    margin-top: -61px;
    overflow: hidden;
    width: 102px;
    z-index: 3;

    @include break-sm {
        background-color: transparent;
        border: 4px solid #fff;
        height: 70px;
        margin-left: -30px;
        margin-top: -20px;
        width: 70px;
    }

    &.is-hidden {
        display: none;
    }

    .icon-video {
        font-size: 50px;
        position: absolute;
        margin-left: -11px;
        margin-top: -24px;
        left: 50%;
        top: 50%;

        @include break-sm {
            color: #fff;
            font-size: 25px;
            margin-left: -5px;
            margin-top: -12px;
        }
    }
}

/* image */
.banner-image-media {
    background-color: $gray-light;
    border-collapse: collapse;
    display: table;
    table-layout: fixed;
    width: 100%;

    .banner-carousel & {
        display: block;
    }

    &.is-noimage {
        background-color: $gray-dark;
    }
}

.banner-image-frame {
    display: table-cell;
    //height: 288px;    
    vertical-align: middle;
    padding-top: 120px;
    padding-bottom: 120px;

    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;  

    @include break-lg {
        padding-top: 90px;
        padding-bottom: 90px;
    } 

    @include break-md {
        padding-top: 60px;
        padding-bottom: 60px;
    } 

    @include break-sm {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .banner-carousel & {
        display: block;
        /*
        @include break-lg (true) {
            height: 558px;
        }

        @include break-lg {
            height: 480px;
        }

        @include break-md {
            height: 400px;
        }

        @include break-sm {
            height: 270px;
            overflow: hidden;
        }

        @include break-xs {
            height: 200px;
        }
        */
    }  

    .is-noimage & {
        height: 150px;
    }

    @include break-sm {
     //   min-height: 180px;

        .is-noimage & {
            height: 110px;
        }
    }

    @include break-xs {
     //   height: 130px;

        .is-noimage & {
            height: 80px;
        }
    }
}

/*  banner carousel */
.banner-carousel {

    &.bancar-handle-loading {        
        overflow: hidden;

        > .bancar-item:not(:first-child) {
            display: none;
        }
    }

    .slick-list {  
    /*       
        @include break-lg (true) {
            max-height: 558px;
        }

        @include break-lg {
            max-height: 480px;
        }

        @include break-md {
            max-height: 400px;
        }

        @include break-sm {
            max-height: 270px;
        }

        @include break-xs {
            max-height: 200px;
        }
        */
    }

    .slick-dots {
        margin: 0 auto -35px auto;
    }
}

.bancar-item {
    overflow: hidden;
    outline: none !important;
}
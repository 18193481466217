/*######################################################
  form
#######################################################*/
.form-wrapper {
    padding: 10px 0 3px 0;

    .modal-body & {
        padding: 0;
    }

    legend {
        color: $gray-darker;
        font-family: $font-secondary-book;
        font-size: 1.8rem;
        padding-top: 25px;
        text-transform: uppercase;
    }

    p {
        font-size: 1.6rem;
        line-height: 2rem;
    }
}

.form-group {
    @include clearfix;
    margin-bottom: 10px;
    .modal & {
        margin-bottom: 20px;
    }
}

.form-item {
    padding-bottom: 10px;
    position: relative;
}

.control-label {
    font-family: $font-secondary;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: normal;
    padding-top: 14px;

}

.form-control {
    @include transition(all ease-in .2s);
    @include box-shadow(none);
    @include border-radius(0);
    color: $gray-darker;
    display: inline-block;
    font-family: $font-primary-bold;
    font-size: 1.8rem;
    font-weight: 500;
    height: inherit;
    line-height: normal;
    padding: 13.5px 16px;
    width: 100%;

    .is-tiny & {
        padding: 2px 6px;
    }
    .is-thin & {
        padding: 4px 10px;
    }
    &:focus {
        @include box-shadow(none);
        border-color: #ccc;
    }

    &:disabled,
    &[disabled="disabled"] {
        background-color: #eee;
        border-color: $border-color;
        color: $gray-lighter;
        cursor: default;
    }  

    .error &,
    &.input-validation-error {
        border-color: $brand-danger;
    }    
}

.form-control-static {
    font-size: 1.5em;
    font-weight: 500;
    line-height: normal;
    padding: 0;
}

.form-field-sm {
    max-width: 178px;
    width: 100%;
}

.form-field-xs {
    max-width: 100px;
    width: 100%;
}

.form-field-xxs {    
    max-width: 80px;
    width: 100%;
}

.form-field-overlay {
    text-align: right;
}

.form-message {
    @include transition(padding ease-in .2s, max-height ease-in .2s);
    font-size: 1.3em;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    
    .error & {
        color: $brand-danger;
        padding:5px 0;
        max-height: 100%;
    }
}

textarea.form-control {
    padding: 16px 20px;
    resize: vertical;
    max-width: 100%;
    .modal & {
        min-height: 180px;
    }
    .is-thin & {
        padding: 6px 10px;
        height: 60px;
    }
}

select.form-control {
    font-family: $font-primary-bold;
    font-size: 1.8rem;
    padding: 18px 30px 18px 16px;

    option {
        font-size: .8em;
        padding-bottom: 10px;
        padding-top: 10px;
        line-height: 50px;
    }
}

input[type=file] {
    font-size: 1.6rem;
}

.is-datepicker {
    position: relative;
    &:before {
        content: "\e900";
        font-family: 'icomoon';
        position: absolute;
        right: 20px;
        top: 12px;
        font-size: 1.6em;
        color: #436dda;
    }
}
label {
    .modal & {
        font-size: 1.6em;
        font-weight: 500;
        color: #888888;
    }
}

legend {
    border: none;
}

.required {
    &:after {
        color: $brand-danger;
        content: "*";
        position: relative;
        top: 4px;
        right: 0;
        margin-left: 2px;
    }
}

.field-validation-error {
    color: $brand-danger;
    display: block;
    font-size: 1.6rem;
    line-height: normal;
    padding: 5px 0 0 0;
}
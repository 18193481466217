/*######################################################
  navbar
#######################################################*/
nav {

}

.nav .open > a, 
.nav .open > a:hover, 
.nav .open > a:focus {
    background-color: #fff;
    border-color: transparent;
}

.navbar-brand {
	//@include background-retina('../Images/law-white-logo.svg', '../Images/law-white-logo.svg');
	background-size: 100%;
	height: auto;
	margin-left: 10px;
	margin-top: 16px;
	padding: 0;
	position: relative;
	outline: 0 none;
	overflow: hidden;
	//text-indent: -333em;
	width: 68px;
	z-index: 3;

	&:focus {
		outline: 0 none;
	}

	@include break-sm (true) {
		.navbar > .container &, 
		.navbar > .container-fluid & {
			margin-left: 0;
		}
	}

	@include break-md {
		width: 64px;		
	}

	@include break-sm {
		margin-top: 4px;
		width: 56px;		
	}

	/*
	@include break-md {
		height: 36px;
		width: 190px;
		margin-top: 20px;
	}

	@include break-sm {
		margin-left: 0;
		margin-top: 7px;
		height: 35px;
    	width: 184px;
	}

	@include break-xs {
		margin-top: 9px;
		height: 34px;
		width: 180px;
	}
	*/

	img {
		@extend .img-responsive;


	}
	
}

.navbar {
	background-color: $gray-darker;
	font-family: $font-primary-regular;
	margin-bottom: 0;
	min-height: 80px;

	@include break-sm {
		min-height: 60px;
		padding: 10px 0;
	}

	@include break-xs {
		min-height: 50px;
	}
}

.navbar-nav {
	font-size: 1.4rem;
	font-weight: normal;
	margin-top: 14px;

	> li {
		float: left;

		> a {
			color: #fff;

			@include break-sm (true) {
				overflow: hidden;				
			}

			&:hover,
			&:focus,
			&:active {
				background-color: transparent;
			}

	    	@include break-sm(true) {
				padding-bottom: 10px;
				padding-top: 10px;
				line-height: 26px;
		    }

		    @include break-xs {
		    	padding: 10px;
		    }
		}

		.dropdown-menu {
			background-color: #fff;
			padding: 30px;

			@include break-sm {
				margin-top: 10px;
			}
		}		

		&.is-has-submenu > a {			
			&:before {
				@extend .icon-arrow-down;

				@include break-sm(true) {	
					@include extend-icomoon;
					font-size: 6px;
					font-weight: bold;
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -8px;
				}

				@include break-sm {	
					display: none;
				}
			}

			@include break-sm(true) {
				padding-right: 18px;
			}
		}
    }

    @include break-sm {
    	float: none !important;
		padding: 0;
		margin: 0;

		> li {
			display: block;
			float: none;
		}
    }
}

.navbar-toggle {
	border: none;

	&:hover,
	&:focus,
	&:active {
		background: none !important;
	}

	@include break-sm {
		margin-right: 0;
		position: relative;
		right: -10px;
		z-index: 10;
	}

	@include break-xs {
		margin-top: 10px;
	}
}

.icon-bar {
  background-color: #fff;
  transition: transform .1s ease;
  -webkit-transition: -webkit-transform .1s ease;
  -moz-transition: transform .1s ease;
  -o-transition: transform .1s ease; 

  @include break-sm {
  	@include transition (all ease-in .2s);

  	.is-menushow & {
  		&:nth-child(2) {
	  		@include transform(rotate(45deg));
	  		margin-top: 6px;
	  	}

	  	&:nth-child(3) {
	  		opacity: 0;
	  	}

  		&:nth-child(4) {
	  		@include transform(rotate(-45deg));
	  		margin-top: -8px;
	  	}


  	}
  }
}

/* mobile navigation layouts */
.navbar-menu-content {
	@include break-sm {		
		@include transition(max-height ease-out .3s);
		background-color: $gray-darker;
		position: absolute; //fixed;
		margin: 0;
		height: 100vh;
		overflow: hidden;
		overflow-y: auto;
		max-height: 0;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 1;

		.is-menushow & {
			@include transition(max-height ease-in .3s);
			max-height: 2000px;
		}
	}
}

.navbar-menu-content-inner {	
	padding: 50px 15px 90px 15px;

	@include break-sm(true) {		
		display: inline;
		padding: 0;
	}

	@include break-sm {
		padding-top: 60px;
	}

	@include break-xs {
		padding-top: 50px;
	}
}

.navbar-collapse {
	@include break-sm {
	}
}


/* top menu */
.top-nav {
	bottom: -1px;
	padding-left: 40px;
    position: relative;

    @include break-lg {
    	padding-left: 30px;
    }

    @include break-md {
    	padding-left: 18px;
    }

	@include break-sm {
		border-top: 1px solid #fff;
		margin-top: 15px;
		padding-left: 0;
		padding-top: 20px;
	}

	> li {
		padding-left: 15px;
		padding-right: 15px;
		text-transform: uppercase;

		@include break-lg {
			padding-left: 11px;
			padding-right: 11px;
		}

		@include break-md {
			padding-left: 10px;
			padding-right: 10px;
		}

		&.active > a {
			color: lighten($gray-darker, 30%);
		}

		> a {
			@include transition(color linear .3s);
			outline: 0 none;
			padding-left: 0;
			padding-right: 0;

			&:hover {
				color: lighten($gray-darker, 30%);
			}
		}		

		> .dropdown-menu {
			background-color: $gray-lighter;
			margin-top: 0;
			padding: 20px;
			right: inherit;
			left: 0;

			@media only screen 
			  and (min-device-width: 768px) 
			  and (max-device-width: 1024px) 
			  and (-webkit-min-device-pixel-ratio: 1) {
				/* tablet, landscape iPad, lo-res laptops ands desktops */ 
				margin-top: -1px;
			}
		}

		&:last-child > .dropdown-menu {
			left: inherit;
			right: 0;
		}

		@include break-sm(true) {
			> .dropdown-menu-toggle {
				display: none;
			}

			&.is-active > a {
				border-color: #fff;
			}

			&.hover,
			&:hover,
			&:focus,
			&:active {
				.dropdown-menu {
					@include transform(scale(1, 1));
					opacity: 1;
				}
			}
		}

		@include break-sm {
			@include clearfix;
			
			> a {
				display: block;
				font-size: 1.6em;
				line-height: 1em;
				padding: 20px 0;
			}

			> .dropdown-menu-toggle {
				@include transition(transform ease .2s);
				@include transform(rotate(0deg));
				cursor: pointer;
				display: block;
				padding: 18px 0;
				position: absolute;
				right: 0;
				font-weight: bold;
				height: auto;
				top: 0;
				width: 30px;
				text-align: center;

				.icon-arrow-down {
					font-size: 10px;
				}
			}

			> .dropdown-menu {
				@include transition(max-height ease-out .3s);
				@include transform(scale(1, 1));
				@include box-shadow(none);
				background: none;
				clear: both;
				opacity: 1;
				overflow: hidden;
				max-height: 0;
				padding: 0;
				position: relative;
				width: 100%;

				a {
					font-size: 1.8rem;
					padding: 10px 0;
				}
			}

			&.is-submenu-open {
				> .dropdown-menu-toggle {
					@include transition(transform ease .2s);
					@include transform(rotate(-180deg));
				}

				> .dropdown-menu {
					@include transition(max-height ease-in .3s);
					max-height: 500px;
				}
			}
		}
	}
}

.submenu {
	list-style: none;
	padding: 0;
	margin: 0 0 10px 0;
	text-transform: none;

	> li {
		> a {
			font-size: 1.4rem;
			font-weight: bold;
			padding: 5px;
			text-transform: uppercase;

			@include break-sm(true) {
				white-space: nowrap;
			}

			&:hover,
			&:focus,
			&:active {			
				text-decoration: none;
				@include break-sm (true) {
					background-color: $gray-light;				
				}
			}
		}
	}
}


.region-nav {
	> li {
		> .dropdown-menu  {		
			margin-top: 11px;
		}

		&.open > .dropdown-menu {	
			&:before {
			    border-style: solid;
			    border-width: 0 12px 12px 12px;
			    border-color: transparent transparent #fff transparent;
			    content: "";
			    display: inline-block;
			    height: 0;
			    position: absolute;
			    right: 3px;
			    top: -11px;
			    width: 0;
			}
		}
	}
}
@mixin breaktext(){
	white-space: pre-wrap;       /* css-3 */
	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	word-wrap: break-word;  
}

@mixin ellipsis(){
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

@mixin border-radius($radius){
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
          overflow: hidden;
}

@mixin transition($transition, $transition2:false, $transition3:false ){
    @if( $transition2 or $transition3  ) {  

        @if( $transition2 and $transition3 == false  ) {  
            transition: $transition, $transition2;
            -webkit-transition: $transition, $transition2;
            -moz-transition: $transition, $transition2;
            -o-transition: $transition, $transition2;
        }

        @if( $transition2 and $transition3  ) {  
            transition: $transition, $transition2, $transition3; 
            -webkit-transition: $transition, $transition2, $transition3;
            -moz-transition: $transition, $transition2, $transition3;
            -o-transition: $transition, $transition2, $transition3;
        }

    } @else {        
        transition: $transition;
        -webkit-transition: $transition;
        -moz-transition: $transition;
        -o-transition: $transition;
    }
}

@mixin box-shadow ($boxshadow){
	box-shadow: $boxshadow;
	-webkit-box-shadow: $boxshadow;
	-moz-box-shadow: $boxshadow;
	-o-box-shadow: $boxshadow;
}

@mixin transform($transform) {
	-moz-transform: $transform;
	-ms-transform: $transform;
	-o-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
    -webkit-backface-visibility: hidden;
}

@mixin transform-origin($origin){
    transform-origin: $origin;
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
}

@mixin animation-fill-mode($animFillMode) {
    animation-fill-mode: $animFillMode;
    -webkit-animation-fill-mode: $animFillMode;
    -moz-animation-fill-mode: $animFillMode;
    -o-animation-fill-mode: $animFillMode;
}

@mixin extend-icomoon (){
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@mixin background-retina($file-1x, $file-2x) {
    @include img-retina($file-1x, $file-2x, 100%, auto);
    background-repeat: no-repeat;
    background-size: cover;
}



// Media Query break points
@mixin break-xs ( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-xs + 1) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-xs-min) {
            @content;
        }
    }
}

@mixin break-ss( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: 600px) {
            @content;
        }
    } @else {

        @media only screen and (max-width: 599px) {
            @content;
        }
    }
}

@mixin break-sm( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-sm) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-xs-max) {
            @content;
        }
    }
}

@mixin break-md( $isMin:false ) {
    
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-sm-max) {
            @content;
        }
    }
}

@mixin break-lg( $isMin:false ) {
    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-lg) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-md-max) {
            @content;
        }
    }
}

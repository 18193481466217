.blockOverlay,
.blockOverlayMessage {
	@include transition(opacity linear .3s);
	background-color: rgba(255, 255, 255, .9);
	position: fixed;
	width: 100%;
	height: 100vh;
	opacity: 1;
	left: 0;
	top: 0;
	z-index: 3333;

	&.transition {
		opacity: 0
	}
}

.blockIcon {
	@include transition(opacity linear .3s);
	z-index: 4444;
    position: fixed;
    padding: 0px;
    margin: 0px;
	opacity: 1;
    width: 100px;
    top: 48%;
    left: 50%;
    margin-left: -54px;
    text-align: center;

	&.transition {
		opacity: 0
	}
}

.blockPopupMessage {	
	@include transition(opacity linear .3s);
	@include box-shadow(0 0px 10px 0px rgba(0, 0, 0, 0.1));
	background-color: #fff;
	z-index: 4444;
    position: fixed;
    padding: 0px;
    margin: 0px;
	opacity: 1;
	padding: 40px;
	max-width: 500px;
    width: 100%;
    top: 30%;
    left: 50%;
    margin-left: -249px;
    text-align: center;

    @media only screen and (max-width: 500px) {
    	width: 100%;
    	left: 0;
    	margin-left: 0;
    }

	&.transition {
		opacity: 0
	}
}

.bpm-title {
	font-size: 2.4rem;
	font-weight: bold;
	margin-bottom: 20px;
}

.bpm-text {
	font-size: 2rem;
	line-height: normal;
}

.bpm-okbtn {
	margin-top: 20px;
}

/* loading icon */
.cssload-container{
	position:relative;
}
	
.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 1px solid rgb(176,218,252);
	border-left-color: rgb(0,114,207);
	border-radius: 974px;
		-o-border-radius: 974px;
		-ms-border-radius: 974px;
		-webkit-border-radius: 974px;
		-moz-border-radius: 974px;
}

.cssload-whirlpool {
	margin: -24px 0 0 -24px;
	height: 49px;
	width: 49px;
	animation: cssload-rotate 1150ms linear infinite;
		-o-animation: cssload-rotate 1150ms linear infinite;
		-ms-animation: cssload-rotate 1150ms linear infinite;
		-webkit-animation: cssload-rotate 1150ms linear infinite;
		-moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::before {
	content: "";
	margin: -22px 0 0 -22px;
	height: 43px;
	width: 43px;
	animation: cssload-rotate 1150ms linear infinite;
		-o-animation: cssload-rotate 1150ms linear infinite;
		-ms-animation: cssload-rotate 1150ms linear infinite;
		-webkit-animation: cssload-rotate 1150ms linear infinite;
		-moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::after {
	content: "";
	margin: -28px 0 0 -28px;
	height: 55px;
	width: 55px;
	animation: cssload-rotate 2300ms linear infinite;
		-o-animation: cssload-rotate 2300ms linear infinite;
		-ms-animation: cssload-rotate 2300ms linear infinite;
		-webkit-animation: cssload-rotate 2300ms linear infinite;
		-moz-animation: cssload-rotate 2300ms linear infinite;
}



@keyframes cssload-rotate {
	100% {
		transform: rotate(360deg);
	}
}

@-o-keyframes cssload-rotate {
	100% {
		-o-transform: rotate(360deg);
	}
}

@-ms-keyframes cssload-rotate {
	100% {
		-ms-transform: rotate(360deg);
	}
}

@-webkit-keyframes cssload-rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes cssload-rotate {
	100% {
		-moz-transform: rotate(360deg);
	}
}
.searchplanspanel {
	.bootstrap-select {
		min-width: 120px;
	}
}

.lessonplanstiles {
	.tiles {
		@include break-lg {	
			overflow:hidden;
			position:relative;
			top:0px;
			text-align:center;
			display:-webkit-flex;
			display:-webkit-box;
			display:-moz-flex;
			display:-moz-box;
			display:-ms-flexbox;
			display:flex;
			/*
			flex-direction:row;
			flex-wrap: wrap;
			*/
			/* Then we define the flow direction and if we allow the items to wrap 
			* Remember this is the same as:
			* flex-direction: row;
			* flex-wrap: wrap;
			*/
			flex-flow: row wrap;
    		-webkit-flex-flow: row wrap; /* Safari 6.1+ */

			/* Then we define how is distributed the remaining space */
			justify-content: center;
		}
	}
}

.lespnst-item {
	@extend .col-lg-4;
	@extend .col-md-6;
	@extend .col-sm-6;
	max-width: 380px;

	@include break-lg(true) {
		&:nth-child(3n+1){
			clear: both;
		}
	}

	@include break-lg {
		float: none;
    	width: 380px;
	}

	@include break-sm {
		&:last-child .tile {
			margin-bottom: 0;
		}
	}

	@include break-xs {
		width: 100%;
	}
}

.lessplansrcfil-filterwrap {

	@include break-sm {
		padding-bottom: 10px;

		.text-right {
			text-align: left !important;
		}
	}
}

.lessplansrcfil-filtercol {
	padding: 0 5px;
	text-align: center;;
	
	@include break-lg {
		padding-bottom: 10px;
	}
}

.lessplansrcfil-btn-clrfilter {
	margin-top: 16px;
}

.lespnst-btnwrap {
	clear: both;
	padding-top: 60px;

	@include break-sm {
		padding-top: 40px;
	}

	@include break-xs {
		padding-top: 20px;
	}
}

// Part of AWIS-58
.lessplansrcfil-subjects {

	&.bootstrap-select.btn-group {

		.dropdown-menu.inner {
			max-height: none !important;
		}
	}
}

.wysiwyg-content {
	h4 {
		margin-bottom: 18px;
		font-size: 1em;
	}
	li {
		margin-bottom: 7px;
	}
	ol {
		li {
			margin-bottom: 14px;
		}
		padding-bottom: 0;
		margin-bottom: 20px;
	}
	ul {
		padding-bottom: 0;
		margin-bottom: 20px;
	}
}

/*######################################################
	layout
#######################################################*/
.l-block {
	display: block;
}

.l-table {
	border-collapse: collapse;
	display: table;
	margin: -1px;
	table-layout: fixed;
	width: 100%;

	&.is-table-auto {
		width: auto;
	}

	&.is-table-break-lg {
		@include break-lg {
			display: block;
			margin: 0;
		}
	}

	&.is-table-break-md {
		@include break-md {
			display: block;
			margin: 0;
		}
	}

	&.is-table-break-sm {
		@include break-sm {
			display: block;
			margin: 0;
		}
	}



	&.is-table-break-ss {
		@include break-ss {
			display: block;
			margin: 0;
		}
	}

	&.is-table-break-xs {
		@include break-xs {
			display: block;
			margin: 0;
		}
	}

}

.l-tablecell {
	display: table-cell;
	vertical-align: top;

	&.has-rightborder-divider {
		border-right: 2px solid $border-color;
	}

	.is-table-break-lg > & {
		@include break-lg {
			display: block;

			&.has-rightborder-divider {
				border-right: none;
			}
		}
	}

	.is-table-break-md > & {
		@include break-md {
			display: block;

			&.has-rightborder-divider {
				border-right: none;
			}
		}
	}

	.is-table-break-sm > & {
		@include break-sm {
			display: block;

			&.has-rightborder-divider {
				border-right: none;
			}
		}
	}

	.is-table-break-ss > & {
		@include break-ss {
			display: block;

			&.has-rightborder-divider {
				border-right: none;
			}
		}
	}

	.is-table-break-xs > & {
		@include break-xs {
			display: block;

			&.has-rightborder-divider {
				border-right: none;
			}
		}
	}
}

.l-inline {
	display: inline-block;
}

.page-colwrap {
	display: table;
	table-layout: fixed;
	width: auto;
	margin: auto;

	@include break-sm {
		@include clearfix;
		display: block;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	&.three-col-layout {
		@include break-md {
			@include clearfix;
			display: block;
		}		
	}
}

.page-col {
	display: table-cell;
	vertical-align: top;

	@include break-sm {
		display: block;
	}

	.three-col-layout > & {
		@include break-md {
			display: block;
		}
	}

	&.is-pgcol-content {		
		max-width: 650px;

		.three-col-layout & {
			@include break-sm {
				max-width: inherit;
			}
		}
	}

	&.is-pgcol-sidebar {
		padding-left: 40px;
		width: 278px;

		@include break-sm {
			padding-left: 0;
			width: 100%;
		}
	}

	&.is-pgcol-sidenav {
		padding-right: 40px;
		position: relative;
		width: 240px;
		z-index: 1;

		~ .page-col {
			position: relative;
			z-index: 0;
		}

		@include break-md {
			padding-right: 50px;
            padding-bottom: 20px;

            .three-col-layout & {
            	padding-right: 0;
            	width: 100%;
            }
		}

	}
}
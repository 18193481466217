.relatedplans {
	.col-sm-4 {
		@include break-sm(true) {
			&:nth-child(3n+1){
				clear: both;
			}
		}
	}
}

.relatedplans {
	.tiles:not(.is-tl-carousel) {
		@include break-lg {			
			width:100%;
			overflow:hidden;
			position:relative;top:0px;
			text-align:center;
			display:-webkit-flex;
			display:-webkit-box;
			display:-moz-flex;
			display:-moz-box;
			display:-ms-flexbox;
			display:flex;
			/*
			flex-direction:row;
			flex-wrap: wrap;
			*/
			/* Then we define the flow direction and if we allow the items to wrap 
			* Remember this is the same as:
			* flex-direction: row;
			* flex-wrap: wrap;
			*/
			flex-flow: row wrap;
    		-webkit-flex-flow: row wrap; /* Safari 6.1+ */

			/* Then we define how is distributed the remaining space */
			justify-content: center;
		}
	}
}

.rltdplns-item {
	@extend .col-lg-4;
	@extend .col-md-6;
	@extend .col-sm-6;
    height: inherit;
    float: left;
	max-width: 380px;

    .is-tl-carousel & {
        width: 100%;

        img {
            @extend .img-responsive;
        }
    }

	@include break-lg(true) {
		&:nth-child(3n+1){
			clear: both;

            .is-tl-carousel & {
                clear:none;
            }
		}
	}

	@include break-lg {
		float: none;
    	width: 380px;

        .is-tl-carousel & {
            float: left;
        }
	}

	@include break-xs {
		width: 100%;
	}
}
/*######################################################
	modal
#######################################################*/
.modal-video,
.modal-image {
	.modal-content {
		@include box-shadow(none);
		background: transparent !important;
		border: none;
	}

	.close {
		color: #fff;
		font-size: 60px;
		font-weight: normal !important;
		outline: none !important;
		opacity: 1;
		filter: alpha(opacity=100);
	}

	.modal-header {
	    padding: 0;
	    border: none;
	}

	.modal-body {
		padding: 0;
	}
}

.modal-image {
	.embed-responsive {
		padding-bottom: inherit;
		img {
			@extend .img-responsive;
		}
	}
}
/*######################################################
	home page
#######################################################*/
.mainhero {
    .btn-md {
        padding-top: 16px;
        padding-bottom: 16px;
        white-space: pre-line;
    }

    .banner-image-frame {        
        min-height: 533px;

        @include break-lg {
            min-height: 473px;
        }

        @include break-md {
            min-height: 413px;
        }

        @include break-sm {
            min-height: 411px;
        }
    }
}


.filtercontent {
	.form-field {
		//margin-left: 10px;

		.bootstrap-select > .dropdown-toggle {
			@include box-shadow(0 1px 1px 0 rgba(0,0,0,0.1));
			border: none;
    		min-height: 48px;
		}
	}

	.bootstrap-select.btn-group {
		.dropdown-toggle .filter-option {
			font-size: 1.8rem;
		}

		.dropdown-menu li a span.text {
			font-size: 1.6rem;
		}
	}

	.wysiwyg-content {
		padding-bottom: 0;
	}
}

.filtercontent-form {
	.form-group {
		@include break-xs {
			margin-bottom: 0;
		}
	}

	.form-item {
		@include break-ss {
			display: block;
			width: 100%;
		}
	}

	.l-table {
		@include break-ss {
			width: 100%;
		}
	}

	.control-label {
		padding-right: 16px;

		@include break-sm {
			text-align: right;
			width: 30%;
		}

		@include break-ss {
			width: 110px;
		}

		@include break-xs {
			padding-right: 0;
			text-align: center;
			width: 100%;
		}
	}

	.form-field {
		@include break-sm {
			width: 70%;
		}

		@include break-ss {
			width:auto;

			.l-inline {
				display: block;
				width: 100%;
			}
		}
	}

	.bootstrap-select {
		@include break-sm {
			width: 300px;
		}
		
		@include break-ss {
			width: 100%;
		}
	}
}

.filtercontent-btn-view {
	@include break-xs {
		width: 100%;
	}
}

div.fltrcnt-users {
	@include break-sm(true) {
		min-width: 310px;
	}
}

div.fltrcnt-category {

	&.bootstrap-select.btn-group {

		.dropdown-menu.inner {
			max-height: none !important;
		}
	}

	@include break-sm(true) {
		min-width: 276px;
	}
}

div.fltrcnt-plans {
	@include break-sm(true) {
		min-width: 210px;
	}
	@include break-sm {
		padding-top: 10px;
	}
}

div.fltrcnt-year {
	@include break-sm(true) {
		min-width: 210px;
	}
}

.whatsnewpanel {

	.col-lg-6 {
		@include break-lg(true) {
			&:nth-child(2n+1){
				clear: both;
			}
		}
	}
	
	.col-md-4 {
		@include break-sm(true) {
			&:nth-child(3n+1){
				clear: both;
			}
		}
	}

	.tile {
		margin-left: auto;
		margin-right: auto;
		max-width: 554px;
	}

    .section-button-wrap {
        @include break-ss {
            margin: auto;
            max-width: 230px;
        }

        @include break-xs {
        	max-width: 100%;
        	.btn {
	        	width: 100%;
	        }
        }
    }
}
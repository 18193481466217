/*######################################################
	reset
#######################################################*/
html,
body{
    margin:0;
    padding:0;

    @include break-sm {
      font-size: 9.5px;
    }

    @include break-ss {
      font-size: 9.2px;      
    }

    @include break-xs {
      font-size: 9px;         
    }
}

body {
  background-color: #f6f6f6;
  color: $gray-darker;
  height: 100vh;
  min-height: 100vh;
  font-weight: 500;
}


html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#mainWrapper {
  @include break-sm(true) {
    min-height: 100%;
    margin-bottom: -185px; 

    &:after {
      content: "";
      display: block;
    }
  }
}

.main-footer, #mainWrapper:after {
  @include break-sm(true) {
    height: 185px; 
  }
}

.container-fluid {
	max-width: 1168px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: lighten($gray-darker, 30%);
  box-shadow: none;
  outline: 0 none;
}


/* handle auto zoom iphone */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
  /* ----------- iPhone 5 and 5S ----------- */
  /* Portrait and Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 16px !important;
  }

  /* ----------- iPhone 6 ----------- */
  /* Portrait and Landscape */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) { 
    font-size: 16px !important;
  }

  /* ----------- iPhone 6+ ----------- */
  /* Portrait and Landscape */
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) { 
    font-size: 16px !important;
  }
}
/*######################################################
	buttons
#######################################################*/
.btn-wrap {
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
}

.btn {
	@include border-radius (0);
	@include transition(all ease-in .125s);
	font-family: $font-primary-bold;
	position: relative;
	z-index: 5;
	
	&:active,
	&:focus,
	&.active {
	  background-image: none !important;
	  outline: none !important;
	  -webkit-box-shadow: none !important;
	          box-shadow: none !important;
	}
}



.btn-xs {
	font-size: 1.2rem;
	padding: 1px 10px;
}

.btn-sm {	
	font-size: 1.4rem;
    padding: 8px 18px;
    min-width: 111px;
    
}

.btn-md {
	font-size: 1.8rem;
	padding: 20px 30px;
	min-width: 150px;
}

.btn-lg {
	font-size: 2rem;
	padding: 20px 50px;

	@include break-xs {
		display: block;
		padding-left: inherit;
		padding-right: inherit;
		width: 100%;
	}
}


.btn-default {	
	&.btn-md {
		padding-left: 45px;
		padding-right: 45px;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: $color-link;
		border-color: $color-link;
		color: #fff;
	}
}

.btn-arrow-right {
	position: relative;
	padding-right: 58px;
	text-align: left;

	&:before {
		@include extend-icomoon;
		@extend .icon-arrow-right;
    	margin-top: -10px;
		position: absolute;
		right: 18px;    
		top: 50%;
	}
}

.btn-downlod {
	background: $gray-darker;
	padding-top: 14px;
	padding-bottom: 14px;
	padding-left: 50px;
	padding-right: 38px;
	position: relative;
	text-align: left;
	white-space: pre-wrap;

	&:before {
		@include extend-icomoon;
		@extend .icon-download;
		font-size: 16px;
		left: 18px;
		position: absolute;
		top: 50%;
		margin-top: -9px;
	}
}

.btn-back {
	padding-left: 50px;
	position: relative;
	text-align: right;

	&:before {
		@include extend-icomoon;
		@extend .icon-chevron-left;
		font-size: 16px;
		left: 18px;
		position: absolute;
		top: 50%;
		margin-top: -8px;
	}
}

.btn-primary {
	/*
	border-color: $color-link;
	background-color: $color-link;

	&:hover,
	&:active,
	&:focus {
		border-color: darken($color-link, 30%);
		background-color: darken($color-link, 30%);
	}
	*/
}

.btn-white {
	border-color: $color-link;
	background-color: #fff;
	color: $gray-darker;

	&:hover,
	&:active,
	&:focus {
		border-color: darken($color-link, 30%);
		background-color: darken($color-link, 30%);
		color: #fff;
	}
}

.btn-loadmore {
	&.btn-md {
		padding-left: 32px;
		padding-right: 32px;
	}

	.load-circle {
		@include border-radius(50%);
		background-color: $gray-dark;
		display: inline-block;
		height: 10px;
		width: 10px;

		&:first-of-type {
			margin-left: 60px;
		}

		+ .load-circle {
			background-color: darken($gray, 30%);
		}

		&:last-child {
			background-color: $gray-darker;
		}
	}
}
/* style for sticky on modern browser
.stickywrap {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
	top: 100px;
}
*/


.dp-sticky {
	/*
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
	top: 100px;
	*/
	position: fixed;
	//top: 90px;

	&--parent {
		position: relative;
	}

	&--end {
		position: absolute !important; 
		top: inherit !important;
		bottom: 0;
	}
}
.formcontainerblock {
	@extend .form-wrapper;
}

.Form__MainBody,
.Form__Status {
	@extend .page-content;
}


.Form__Element {
	&.FormStep {
		@include make-row;
	}

	&.FormTextbox {
		@extend .form-item;
	}

    &.FormSelection  {
        
        .bootstrap-select {
        	> .dropdown-toggle {
	            min-height: 58px;

	            .filter-option {
	                font-size: 1.6rem;
	            }
	        }

	        ul.dropdown-menu.inner {
	        	.disabled {
	        		display: none;
	        	}
	        }
        }
    }
}

.Form__Status__Message,
.Form__Status__Message p,
.Form__Status__Message span {	
    font-size: 2rem;
    line-height: normal;
}

.Form__Title {
	@extend .section-title;
}

.Form__Element__Caption {
	@extend .control-label;
	font-size: 1.6rem;
	margin-bottom: 10px;

	.ValidationRequired & {
		@extend .required;
	}
}

.FormTextbox__Input {
	@extend .form-control;
	@include box-shadow(0px 2px 2px -2px rgba(0, 0, 0, 0.2));
	border: none;
	font-size: 1.6rem;
	padding: 18px 16px;

	.ValidationFail.ValidationRequired & {		
        border-color: $brand-danger;
	}
}

.Form__Element {
	textarea:focus,
	input[type="text"]:focus,
	input[type="password"]:focus,
	input[type="datetime"]:focus,
	input[type="datetime-local"]:focus,
	input[type="date"]:focus,
	input[type="month"]:focus,
	input[type="time"]:focus,
	input[type="week"]:focus,
	input[type="number"]:focus,
	input[type="email"]:focus,
	input[type="url"]:focus,
	input[type="search"]:focus,
	input[type="tel"]:focus,
	input[type="color"]:focus,
	.uneditable-input:focus {   
	  @include box-shadow(0px 3px 2px -2px rgba(0, 0, 0, 0.2) !important);
	}
}

textarea.FormTextbox__Input {
	font-size: 1.6rem;
	min-height: 150px;
}

.Form__Element__ValidationError {
	@extend .field-validation-error;
}


.submitbuttonelementblock {
	padding: 20px 0;
	text-align: center;
}

.FormSubmitButton {
	@extend .btn;
	@extend .btn-primary;
	@extend .btn-md;
	@extend .btn-arrow-right;
	max-width: 350px;
	text-transform: uppercase;
	width: 100%;
}

.FormParagraphText {
    font-family: $font-secondary-book;
    font-size: 2rem;

    p {
    	margin-top: 10px;
    	margin-bottom: 0;
    }
}

/* display layout */
.displaymode-clear {
	@include break-sm(true) {
		clear: both;
	}

	@include break-sm {
		clear: both;
	}
}

.displaymode-full {
	clear: both;
}

.EPiServerForms .Form__Status .Form__Success__Message {
	background-color: #fff !important;
    padding: 30px 20px !important;
    font-weight: bold !important;
}
.tiles {
    @include clearfix;
	padding-top: 40px;
	padding-bottom: 20px;

	@include break-sm {
		padding-top: 20px;
		padding-bottom: 15px;
	}

	@include break-xs {
		padding-top: 15px;
		padding-bottom: 10px;
	}
}

.tile {
	@include transition(all .2s ease-in-out); 
	margin-bottom: 30px;
	position: relative;

	&:hover { 
		.tiles & {
			@include transform(scale(1.05)); 
			@include box-shadow(0 0 12px 0 rgba(0,0,0,0.2));

			@include break-sm {
				@include transform(scale(1.02)); 
			}
		}
		/* hover states styles*/
		.tl-links li {
			color: $gray-base;
		}

		.is-tl-var-1 & {
			.tl-title:before {
				@include break-xs (true) {
					opacity: 1;
				}
			}
		}
	}

	a {
		color: $gray-base;
		text-decoration: none;
	}	
}

.tl-content {
	position: relative;
	background-color: $gray-lighter;
	left: 0;
	top: 0;


	.is-tl-var-1 & {
		@extend .l-table;
		margin: 0 !important;
	}

	.is-tl-var-2 & {
		background-color: #fff;
	}

	.is-tl-var-3 & {
		background-color: #fff;
		text-align: center;
	}		
}

.tl-header {
	@include clearfix;
	background-color: $gray-darker;
	color: #fff;
	display: block;
	font-family: $font-primary-bold;
	width: 100%;

	.is-colvar-1 & {
		background-color: $brand-success;
	}

	.is-colvar-2 & {
		background-color: $brand-primary;
	}

	.is-colvar-3 & {
		background-color: darken($brand-danger, 10%);
	}

	.is-colvar-4 & {
		background-color: $brand-danger;
	}

	.is-colvar-5 & {
		background-color: $brand-danger;
	}
    .is-colvar-6 & {
		background-color: $color-media;
	}
}

/*
.tl-headcol {
	display: table-cell;
	vertical-align: top;
}
*/

.tl-head-title {
	@include ellipsis;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

.tl-headlabel {
	font-size: 1.6rem;
	font-weight: normal;
	line-height: 1;
	overflow:hidden;
	padding: 16px 5px 16px 20px; 
	text-align: left;
}

.tl-headcat {
	float:right;
	margin-left: 2px;
}

.tl-headcatwrap {
	@include clearfix;
	float: right;
	max-width: 156px;
}

span.tl-hc {
	background-color: rgba(255, 255, 255, 0.2);
	display: block;
	font-size: 1.8rem;
	font-weight: normal;
	float: left;
	height: 50px;
	padding: 12px 0;
	line-height: 1.4em;
	margin-left: 2px;
	text-align: center;
	width: 50px;	
}

.tl-date {
	display: block;
	font-family: $font-secondary-bold;
	font-size: 1.2rem;
	line-height: normal; 
	position: relative;
	margin-top: -10px;
	margin-bottom: 10px;
}

.tl-image {
    //background-color: $gray;
	position: relative;
	
	img {
		@extend .img-responsive;
		height: auto;
		width: 100%;
		z-index: 0;
	}

	&:before {
		@include extend-icomoon;
		color: #fff;
		font-size: 160px;
		font-weight: normal;
		left: 50%;
		margin-top: -80px;
		position: absolute;
		top: 50%;
		margin-left: -86px;
		z-index: 5;
		text-shadow: 1px 1px 4px #c0c0c0;
	}

	.is-tl-link & {
		&:before {
			@extend .icon-tilt-link;			
			font-size: 86px;
			margin-left: -50px;
			margin-top: -46px;
		}
	}

	.is-tl-doc & {
		&:before {
			@extend .icon-doc;
			font-size: 60px;
			margin-top: -30px;
		}
	}

	.is-tl-pdf & {
		&:before {
			@extend .icon-pdf;
		}
	}

	.is-tl-vid & {
		&:before {
			@extend .icon-video-play;
			font-size: 80px;
			margin-top: -38px;
			margin-left: -50px;
		}
	}

	.is-url-video & {
		&:before {
			@extend .icon-video-play;
				font-size: 50px;
				margin-top: -28px;
				margin-left: -28px;		
		}
	}

	.is-tl-jpg & {
		&:before {
			@extend .icon-jpg;
		    font-size: 60px;
		    margin-top: -30px;
		}

	}

	.is-url-video {
		&:before {
			@extend .icon-video-play;
			@include extend-icomoon;
			color: #fff;
			font-size: 50px;
			font-weight: normal;
			left: 50%;
			position: absolute;
			top: 50%;
			margin-top: -28px;
			margin-left: -28px;
			z-index: 5;		
		}
	}

	.is-tl-var-1 & {
		@extend .l-tablecell;
		@include break-ss(true) {
			width: 200px;
		}
		@include break-ss {
    		overflow: hidden;
			width: 180px;
		}

		@include break-xs {
			display: block !important;
			width: 100%;
		}
	}

	.is-tl-var-3 & {
		background-color: $gray-light;
		min-height: 200px;
	}

}

.tl-title {
	color: $gray-base;
	font-family: $font-secondary-bold;
	font-size: 2rem;
	line-height: 1.3em;
	margin: 0;
	padding-bottom: 20px;
	position: relative;

	a & {
		color: $gray-base;		
	}

	.is-tl-var-1 & {
		&:before {			
			@include transition(opacity .2s linear);
			@include extend-icomoon;
			@extend .icon-arrow-right;
			color: $gray-darker;	
			font-size: 1.4rem;
			font-weight: bold;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -15px;
		}

		@include break-sm(true) {
			padding-right: 32px;
		}
	}

	.is-tl-var-2 & {
		text-align: center;
	}
}

.tl-desc {
	padding-top: 25px;
	padding-bottom: 25px;

	@include break-sm {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	@include break-ss(true) {
		padding-left: 20px;
		padding-right: 20px;
	}

	@include break-ss {

		// JIRA Ticket: LAW-89

		//padding-left: 12px;
		//padding-right: 12px;
		padding-left: 20px;
		padding-right: 20px;

	}

	@include break-xs {
		display: block !important;
		width: 100%;
	}
}

.tl-caption {
	font-family: $font-secondary-book;
	font-size: 1.6rem;
	line-height: 1.7em;

	@include break-sm {
		line-height: 1.7em;
	}

	@include break-ss {
		line-height: 1.4em;
	}

	@include break-xs {
		line-height: 1.6em;
	}

	// JIRA Ticket: LAW-89

	//.is-tl-var-1 & {
	//	@include break-sm(true) {
			//padding-right: 30px;
		//}
	//}

	.is-tl-var-2 & {
		text-align: center;
	}

	a & {
		color: $gray-darker;		
	}
}

.tl-empty-message {
	font-family: $font-secondary-book;
    font-size: 1.8rem;
    margin: auto;
    text-align: center;
    padding: 100px 0;
}

.tl-pagination {
	padding: 10px 0;
	text-align: center;

	ul {
		display: block;
		margin: auto; 
		padding: 0;
		text-align: center;
	}

	li {
		@include clearfix;
		display: inline-block;
		padding: 0 5px;
		list-style: none;
		margin-bottom: 10px;

		a {
			@include transition(all ease-in-out 0.1s);
			border: 2px solid $gray-darker;
			font-family: $font-primary-bold;
			display: block;
			font-size: 1.8rem;
			padding: 10px 17px;
			text-decoration: none;

			&.disabled {
				border-color:$gray-dark; 
				cursor: default;
				color: $gray-dark;
			}

			&.more {
				border-color: transparent;
				padding-left: 2px;
				padding-right: 2px;
			}

			&.tl-pg-icon {
				height: 49px;
				padding-top: 16px;
				font-size: 1.4rem;
				font-weight: normal;
			}

			&.current,
			&:not(.more):not(.disabled):hover {
				background-color: $gray-darker;
				color: #FFF;
			}
		}
	}
}

.tl-pagination-count {
    color: $gray-dark;
    font-family: $font-primary-medium;
    padding: 10px 5px;
    text-align: right;

    span {
        display: inline-block;
        padding-left: 3px;
    }

}

.is-tl-carousel {
    padding-top: 30px;
    padding-bottom: 10px;
}

.tl-car-wrap {    
    @include transition(opacity linear .2s);
    opacity: 0;
    max-height: 300px;
    overflow: hidden;

    &.slick-initialized {
        max-height: inherit;
        opacity:1;
        overflow: visible;
    }

    .slick-list {
        padding: 15px 0;
        margin-top: -15px;
    }

    @media (max-width: 1200px) {
        margin: auto;
        max-width: 760px;
    }

     @media (max-width: 820px) {
         max-width: 380px;
     }

     @media (max-width: 460px) {
         padding-left: 20px;
         padding-right:20px;
     }
}

.tl-carnav {
    @include transition(opacity linear .2s);
    color: $gray-dark;
    cursor: pointer;
    font-size: 3rem;
    position: absolute;
    opacity: 1;
    top: 50%;
    margin-top: -10px;

    &.slick-disabled {
        cursor: default;
        opacity: 0;
    }   
}

.tl-carnav-left {
    left: -20px;    
    @media (max-width: 460px) {
        left: -5px;
    }
}

.tl-carnav-right {
    right: -20px;    
    @media (max-width: 460px) {
        right: -5px;
    }
}

.tl-car-item {
    height: inherit;
}




.tl-links {
	display: table;
	list-style: none;
	padding: 0;
	margin: 20px auto 0 auto;

	li {
		@include transition(color linear .2s);
		color: $gray-dark;
		display: table-cell;
		font-family: $font-secondary-book;
		font-size: 1.2rem;
		padding: 5px 0 5px 30px;
		position: relative;
		text-align: left;

		&:before {
			@include extend-icomoon;
			font-size: 2.2rem;	
			position: absolute;
			left: 0;
			top: 2px;	
		}

		+ li {
			padding-left: 45px;

			&:before {
				left: 15px;
			}
		}
	}


	span {
		display: inline-block;

		+ span {
			padding-left: 5px;
		}
	}
}

.tl-lnkdoc-circle {
	
		&:before {
			@extend .icon-doc-circle;
		}
}

.tl-lnkvid-circle {
		&:before {
			@extend .icon-video-circle;
		}
}

.tl-lnkview {
		color: $gray-base;
		padding-left: 24px;
		&:before {
			@extend .icon-view;
				font-size: 1.8rem;
		}
}

.tl-lnkdld {
		color: $gray-base;
		padding-left: 24px;
		&:before {
			@extend .icon-download;
			font-size: 1.6rem;
		}

		&:hover {
			text-decoration: underline !important;
		}
}

.tl-lnklink {
	&:before {
		@extend .icon-link;
	}
}
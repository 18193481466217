/*######################################################
	content
#######################################################*/
/* main hero */
.page-title {
	//color: #333; 	
	font-family: $font-primary-bold;
	font-size: 2.8rem;
	line-height: normal;
	margin: 0;
	padding: 0 0 20px 0;
	text-transform: uppercase;

	&.is-small-content {
		margin: auto;
		max-width: 768px;
	}
}

.sidepanel-title {
	color: $gray-base;
	font-family: $font-primary-black;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1;
}


/* list link */
.list-link {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: block;
		padding-bottom: 12px;
	}

	a {
		color: $gray-base;
		display: block;
		padding-left: 22px;
		position: relative;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			text-decoration: underline;
		}

		&:before {
			@include extend-icomoon;
			@extend .icon-link;
			font-size: 1.4rem;
			font-weight: bold;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

.lstlnk-label {
	display: block;
	font-family: $font-secondary-bold;
	font-size: 1.4rem;
	line-height: 1em;
}

.lstlnk-desc {
	display: block;
	font-family: $font-secondary-book;
	font-size: 1.4rem;
	line-height: normal;
	padding-top: 8px;
}

.dropdown-selectcolour {
	&.lessplansrcfil-subjects {
		max-width: 350px;
		width: 100%;
		//width: 208px;
	}
}


// video
.modal-video.modal.fade .modal-dialog {
	@include transform(translate(0, 0));
}

.embed-responsive {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	iframe {
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// fix issue embedded video on body
.video-wrapper iframe.vimeo {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%!important;
	height: 100%!important;
	z-index: 9;
}


.btn-backpage {
	font-size: 1.8rem;
	padding-bottom: 16px;
	padding-top: 16px;
	margin-bottom: 20px;

	@include break-xs(true) {
		padding-bottom: 12px;
		padding-top: 12px;
		max-width: 150px;
	}
}

.three-col-layout  .is-pgcol-content.bg-white {	
	.wysiwyg-content {
		padding-bottom: 40px;
		padding-left: 30px;
		padding-right: 30px;

		h4 {
			margin-bottom: 0;
			font-size: 1em;
			line-height: 1.6em;
		}
		li {
			margin-bottom: 7px;
		}
		ol {
			li {
				margin-bottom: 14px;
			}
			padding-bottom: 0;
			margin-bottom: 20px;
		}
		ul {
			padding-bottom: 0;
			margin-bottom: 20px;
		}
	}

	.page-title {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include break-sm {
		margin-bottom: 30px;

		.wysiwyg-content {
			padding-bottom: 30px;
			padding-left: 20px;
			padding-right: 20px;
		}
		
		.page-title {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.three-col-layout .page-col.is-pgcol-sidenav .sidepanel:last-child {
	@include break-md {
		padding-bottom: 0;
	}
}

.article-date {
	display: block;
	padding-bottom: 14px;
}


/* video wrapper */
.video-wrapper {
	text-align: center;
}

iframe.youtube {
	max-width: 100%;
}

/* News and Events Tiles and Pages */
.whatsnew-item,
.newsevents-item {
	> .tl-content {
		> .tl-image {

			/*
			@include break-xs {
				height: auto;

				> img {
					position: relative;
				}
			}
			*/

			&.is-url-video {
				&:before {
					@extend .icon-video-play;
					@include extend-icomoon;
					color: #fff;
					font-size: 50px;
					font-weight: normal;
					left: 50%;
					position: absolute;
					top: 50%;
					margin-top: -28px;
					margin-left: -28px;
					z-index: 5;
				}

				> img {
					z-index: 0;
				}
			}
		}

		> .tl-desc {
			display: table-cell;
		}
	}	
}

.page-section.bg-white + .whatsnew-section.bg-white {
	.section-inner {
		padding-top: 0;
	}
}

// feedback
.section-feedback {
	background-color: #7f7f7f;
}


//LAW-74
.video-wrapper {
	background: #F8F8F8;
	overflow: hidden;
	padding-bottom: 53%;
	/* 16:9 */
	padding-top: 3.3%;
	position: relative;
	vertical-align: top;
	&.contain-video {
		height: 0;
		img {
			display: none;
		}
	}
	img {
		margin-top: -25px;
		position: absolute;
		height: auto;
		width: 100%;
		vertical-align: top;
	}
	.play-icon {
		top: 50%;
		left: 50%;
		width: 100px;
		height: auto;
		margin: -50px 0 0 -50px;
		z-index: 1;
	}
}
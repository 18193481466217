.searchpanel {

}

.srchpnl-filterwrap {
	margin-left: auto !important;
	margin-right: auto !important;
	width: auto;
}

.srchpnl-filtercol {
	padding-left: 6px;
	padding-right: 6px;
}

.srchpnl-category {
	display: table;
	margin: 20px auto;
	padding: 0;
	list-style: none;

	@include break-sm {
		margin: 15px auto;
	}

	@include break-xs {
		margin: 10px auto;
	}
}

.srchpnl-catlist {
	display: table-cell;
	padding: 0 10px;
	vertical-align: top;

	+ .srchpnl-catlist {
		padding-left: 120px;
		padding-right: 20px;

		@include break-sm {
			padding-left: 60px;
		}

		@include break-ss {
			padding-left: 40px;
		}

		@include break-xs {
			padding-left: 20px;
		}
	}

	label {
		padding-left: 40px;

		.icon-checkbox:before {
			background: none;
			border: none;
			font-size: 20px;
			padding: 0;
			left: 10px;
		}
	}
}

.srchpnl-form-wrap {
	padding-bottom: 20px;
	padding-top: 20px;
	position: relative;
	text-align: center;

	@include break-ss(true) {
		&:before {
			border-top: 2px solid $border-color;
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			height: 1px;
			margin-top: -1px;
			width: 100%;
			z-index: 0;
		}
	}

	@include break-sm {
		padding-bottom: 10px;
		padding-top: 10px;
	}
}

.srchpnl-form-field {
	display: inline-block;
	max-width: 524px;
	margin: auto;	
	padding-right: 94px;
	position: relative;
	width: 100%;
	z-index: 2;

	&:before {
		@include extend-icomoon;
		@extend .icon-search;
		border-right: 1px solid $border-color;
		font-size: 2rem;
		position: absolute;
		height: 30px;
		top: 9px;
		left: 15px;
		padding-right: 14px;
		padding-top: 5px;
		z-index: 10;
	}
}

.is-srchpnl-remove {
	@include transition(opacity linear .2s);
	@include extend-icomoon;
	@extend .icon-remove;
	color: lighten($gray-darker, 20%);
	cursor: pointer;
	font-size: 24px;
	position: absolute;
	z-index: 10;
	right: 104px;
	top: 50%;
	margin-top: -12px;
	opacity: 0;

	.is-has-text & {
		opacity: 1;
	}
}

.srchpnl-form-button {
	font-size: 1.4rem;
    height: 50px;
    padding: 10px 5px;
	position: absolute;
    right: 0px;
    top: 0px;
    z-index: 6;
    outline: none;
    min-width: 94px;

    &[disabled] {
    	border-color: $gray-dark !important;
    	background-color: $gray-dark !important;
    	opacity: 1 !important;
    	color: lighten($gray-darker, 50%) !important;
    }

    .is-disabled & {
    	color: $gray-light;
    }
    
}

.srchpnl-form-apply {
	font-size: 1.4rem;
    height: 50px;
    padding: 10px 5px;	
    min-width: 94px;

    @include break-lg {
    	max-width: 350px;
    	width: 100%;
    }
}

.srchpnl-form-input {
	background-color: #fff;
	border: 1px solid $border-color;
	font-family: $font-secondary;
	font-size: 1.6rem;
	padding: 13px 50px 13px 60px;
	position: relative;
    height: 50px;
	z-index: 4;
	width: 100%;

	.is-disabled & {
		background-color: $gray-lighter;

		&::-webkit-input-placeholder {
		  color: $gray-light;
		}
		&::-moz-placeholder { 
		  color: $gray-light;
		}
		&:-ms-input-placeholder {
		  color: $gray-light;
		}
		&:-moz-placeholder {
		  color: $gray-light;
		}
	}
}

.srchpnl-btnwrap {
	padding-top: 0;
	padding-bottom: 0;
	text-align: center;

	@include break-sm {
		text-align: center;		
	}	
}

.srchpnl-btn {
	@include break-sm {
		width: 300px;
	}

	@include break-xs {
		width: 100%;
	}
}

.srchpnl-btn-orfilter {
	color: rgba(92,92,96,0.6);
	font-size: 1.2rem;
	padding-bottom: 16px;
	position: relative;
	margin-bottom: -15px;

	&:active,
	&:hover,
	&:focus {
		color: lighten($gray-base, 50%);
		text-decoration: none;
	}

	.icon-dropdown {
		position: absolute;
		left: 50%;
		margin-left: -6px;
		bottom: 0;
	}	
}

.srchpnl-btn-clrfilter {
	color: rgba(92,92,96,0.6);
	font-size: 1.2rem;
	position: relative;
	margin-top: -4px;
	margin-bottom: -15px;

	&:active,
	&:hover,
	&:focus {
		color: lighten($gray-base, 50%);
		text-decoration: none;
	}

	.icon-close {
		padding-left: 6px;
	}
}

.srchpnl-lbl-filterresulsts {
	color: rgba(92,92,96,0.6);
	font-size: 1.2rem;
	padding-bottom: 16px;
	position: relative;
	margin-bottom: -15px;

	&:active,
	&:hover,
	&:focus {
		color: lighten($gray-base, 50%);
		text-decoration: none !important;
	}

	.icon-dropdown {
		position: absolute;
		left: 50%;
		margin-left: -6px;
		bottom: 0;
	}	
}


.srchpnl-text {
	display: block;
	font-family: $font-secondary-book;
	text-align: center;

	span {
		display: inline-block;
		font-size: 1.6rem;
		line-height: 1;
		padding: 10px 6px;
		position: relative;
		margin-bottom: 18px;

		&:before,
		&:after {
			border-top: 2px solid $border-color;
			content: "";
			display: block;
			position: absolute;
			right: -6px;
			top: 50%;
			width: 6px;
		}

		&:before {
			left: -6px;
		}
	}

	@include break-sm {
		span {
			margin-bottom: 0;
		}
	}

	&.is-hidden {
		visibility: hidden;
		opacity: 0;
	}
}


.srchpnl-btnwrap-left {
	@include break-sm(true) {
		text-align: right;
	}

	@include break-sm {
		padding-bottom: 10px;
	}
}

.srchpnl-btnwrap-right {
	@include break-sm(true) {
		text-align: left;
	}

}
/*######################################################
	section
#######################################################*/
.page-section {

    &.is-full-bg {
        background-position: center top;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: relative;
    }
}

.section-inner {
    padding: 60px 0;

    @include break-sm {
        padding: 35px 0;
    }

    @include break-xs {
        padding: 20px 0;
    }

    &.is-secinpadbotless {
        padding-bottom: 0;
    }
}

.section-inner--pdSm {
    padding: 40px 0 30px 0;

    @include break-sm {
        padding: 30px 0;
    }

    @include break-xs {
        padding: 20px 0;
    }

    &.is-secinpadbotless {
        padding-bottom: 0;
    }
}

.section-title {
    font-family: $font-primary-bold;
    font-size: 2.8rem;
    line-height: normal;
    margin: 0;
    padding: 0 0 20px 0;
    text-transform: uppercase;
    text-align: center;
}

.section-intro {
    line-height: 32px;
    max-width: 768px;
    margin: auto;
    padding: 0 0 20px 0;
}

.section-button-wrap {
    &.is-btn-has-minwidth {
        .btn {
            min-width: 217px;
        }
    }

    @include break-ss (true) {
        .btn {
            ~ .btn {
                margin-left: 30px;
            }
        }
    }

    @include break-ss {
         .btn {
            ~ .btn {
                margin-top: 20px;
            }
        }
    }
}

/*######################################################
	paragraph
#######################################################*/
.p-xlg {
	font-size: 3rem !important;
	line-height: 1.8em !important;
}

.p-lg {
	font-size: 2.4rem !important;
	line-height: 1.8em !important;
}

.p-md {
	font-size: 1.8rem !important;
    line-height: 1.8em !important;
}

.p-sm {
	font-size: 1.6rem !important;
	line-height: 2em !important;
}

.p-xs {
	font-size: 1.4rem !important;
	line-height: 2.2em !important;
}

.p-xxs {
	font-size: 1.2rem !important;
	line-height: 2.2em !important;
}

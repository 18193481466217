/*------------------------------------------
	font-face icomoon
------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src:    url('../Fonts/icomoon/icomoon.eot?kbzcwu');
  src:    url('../Fonts/icomoon/icomoon.eot?kbzcwu#iefix') format('embedded-opentype'),
      url('../Fonts/icomoon/icomoon.ttf?kbzcwu') format('truetype'),
      url('../Fonts/icomoon/icomoon.woff?kbzcwu') format('woff'),
      url('../Fonts/icomoon/icomoon.svg?kbzcwu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-navigation-right:before {
  content: "\e900";
}
.icon-navigation-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-doc:before {
  content: "\e904";
}
.icon-doc-circle:before {
  content: "\e905";
}
.icon-download:before {
  content: "\e906";
}
.icon-dropdown:before {
  content: "\e907";
}
.icon-link:before {
  content: "\e908";
}
.icon-pdf:before {
  content: "\e909";
}
.icon-pdf-square:before {
  content: "\e90a";
}
.icon-chevron-left:before {
  content: "\e90b";
}
.icon-remove:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-tickmark:before {
  content: "\e90e";
}
.icon-video:before {
  content: "\e90f";
}
.icon-video-circle:before {
  content: "\e910";
}
.icon-video-play:before {
  content: "\e911";
}
.icon-view:before {
  content: "\e912";
}
.icon-tilt-link:before {
  content: "\e913";
}
.icon-jpg:before {
  content: "\e914";
}
/*######################################################
	typography
#######################################################*/
body.is-menushow {
	@include break-sm {
		overflow: hidden;
	}
}

a {
	@include transition(color ease-in .2s);
	color: $gray-darker;

	&:hover,
	&:focus,
	&:active {
		color: lighten($gray-base, 40%);
	}
}

main.main-content {
	background-color: $gray-lighter;
	padding-top: 80px;

	@include break-sm {
		padding-top: 69px;
	}

	@include break-xs {
		padding-top: 71px;
	}
}

.page-content {
	margin: auto;
	max-width: 768px;
	width: 100%;

	&-2 {
		margin: auto;
		max-width: 900px;
		width: 100%;
	}
}


strong {
	font-weight: 700;
}

p {
    font-size: 1em;
}

dl {
	margin: 0;
	overflow:hidden;
	width:100%;

	dt {
		clear: both;
		float:left;
		padding: 5px 0 0 0;
		text-align: left;
		width:45%;
	}

	dd {
		float:left;
		padding: 5px 0 0 0;
		text-align: left;
		width:55%; 
	}
}

.fn-prm {
	font-family: $font-primary !important;
}

.fn-prm-bold {
	font-family: $font-primary-bold !important;
}

.fn-prm-med {
	font-family: $font-primary-medium !important;
}

.fn-snd {
	font-family: $font-secondary !important;
}

.fn-snd-bold {
	font-family: $font-secondary-bold !important;
}

.fn-snd-book {
	font-family: $font-secondary-book !important;
}

.tx-uppercase {
	text-transform: uppercase;
}

.basic-fontstyle {
	font-size: 1.5em;
	font-weight: 500;
	line-height: 1.4em;
}

.wysiwyg-content {
	//color: #333;
	font-family: $font-secondary-book;
	font-size: 1.6em;
	font-weight: normal;
	line-height: 1.75em;
	padding-bottom: 50px;

	@include break-sm {
		padding-bottom: 30px;
	}

	@include break-xs {
		padding-bottom: 20px;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: $font-secondary-bold;
	}

	h2 {
		font-size: 2.2rem;
		line-height: 1.8em;
		margin-bottom: .8em;
	}

	h3 {
		font-size: 1.7rem;
		margin-bottom: .8em;
	}

	h4 {
		font-size: 1.4rem;
	}

	h5 {
		font-weight: 1rem;
		font-size: .8em;
	}

	strong,
	b {
		font-family: $font-secondary-bold;
	}

	a:not(.btn) {
		text-decoration: underline;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}
	}

	p {
		font-size: 1em;
		//padding-bottom: 1em;
		margin-bottom: 24px;

		//&.intro,
		//&:first-of-type { //AWIS-47
		&.intro {
		  font-size: 1.25em;
		  line-height: 1.6em;
		  //padding-bottom: 0.75em;
			margin-bottom: 20px;
		}

		&:last-of-type {
			padding-bottom: 0;
		}
	}

	ul, ol {
		padding-bottom: 1em;
		margin-bottom: 7px;
	}


	img {
		@extend .img-responsive;
	}
	
	.figcaption {
	    font-size: 1.3rem;
	    color: $gray-darker;
	    text-align: center;
	    text-transform: uppercase;
	}

	@include break-sm(true) {
		.image-right,
		.image-align-right {		
		    float: right;
		    max-width: 350px;
		    padding: 10px 0 10px 20px;
		}

		.image-left,
		.image-align-left {
		    float: left;
		    max-width: 350px;
		    padding: 10px 20px 10px 0;
		}
	}

	@include break-sm {
		.image-right,
		.image-left,
		.image-align-right,
		.image-align-left {	
			display: block;
			text-align: center;

			img {
				margin: auto;
			}
		}
	}

	.image-article-large {
		width: 100%;
	}

	.article-intro,
	.intro {
		font-size: 2.2rem;
		line-height: 1.8em;
		margin-bottom: .8em;

		> p {
			font-size: 2.2rem;
			line-height: 1.8em;
			margin-bottom: .8em;
		}
	}

	.align-center {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

	.standout {
		background-color: $gray-light;
		padding: 15px 20px;
		margin-bottom: 20px;

		p:last-child {
			padding-bottom: 0;
		}
	}
}


.content-wrapper {
	background-color: #fff;
	padding: 30px 40px;

	~ .content-wrapper {
		margin-top: 40px;
	}

	@include break-md {
		padding: 20px 30px;
	}

	@include break-sm {
		padding: 15px 20px;
	}

	@include break-xs {
		padding: 10px;
	}
}

.borderless {
	border: none !important;
}

.borderbot2 {
	border-bottom: 2px solid $border-color !important;
}

.marginless {
	margin: 0 !important;
}

.padleftless {
	padding-left: 0 !important;
}

.padrightless {
	padding-right: 0 !important;
}

.padtopless {
	padding-top: 0 !important;
}

.padbotless {	
	padding-bottom: 0 !important;
}

.padbot80 {
	padding-bottom: 80px !important;

	@include break-md {
		padding-bottom: 80px !important;
	}

	@include break-sm {
		padding-bottom: 50px !important;
	}

	@include break-xs {
		padding-bottom: 20px !important;
	}
}

.divider {
	border-top: 1px solid $border-color;
	height: 1px;
	margin-bottom: 30px;
	margin-top: 35px;

	@include break-sm {
		margin-bottom: 20px;
		margin-top: 25px;
	}

	@include break-xs {
		margin-bottom: 12px;
		margin-top: 15px;
	}

	&.is-botless {
		margin-bottom: 0;
		@include break-sm {
			margin-bottom: 0;
		}
		@include break-xs {
			margin-bottom: 0;
		}
	}
}

.readmore {
	@include transition (color  ease-in .2s);
	color: #000;
	display: inline-block;
	font-size: 14px;
	line-height: normal;

	&:hover,
	&:focus,
	&:active {
		color: lighten(#000, 40%);
		text-decoration: none;
	}
}
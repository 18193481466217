/* Base variables */
$screen-lg      : 1200px;

$screen-md-max  : $screen-lg - 1;
$screen-md      : 992px;
$screen-md-min  : $screen-md; 

$screen-sm-max  : $screen-md - 1;
$screen-sm      : 768px;
$screen-sm-min  : $screen-sm; 

$screen-xs-max  : $screen-sm - 1;
$screen-xs      : 480px;
$screen-xs-min  : $screen-xs; 



$border-color: rgba(51, 51, 51, 0.2);
$border-secondary: #dde6fd;

$color-primary	: #003378;
$color-success	: #84BD00;
$color-info		: #5bc0de;
$color-warning	: #f0ad4e;
$color-danger	: #E2231A; 

$color-link :  #747779; 
$color-media:  #765aa3;

$font-primary 			: 'Conv_DINPro-Light', sans-serif;
$font-primary-black		: 'Conv_DINPro-Black', sans-serif;
$font-primary-bold		: 'Conv_DINPro-Bold', sans-serif;
$font-primary-medium	: 'Conv_DINPro-Medium', sans-serif;
$font-primary-medium	: 'Conv_DINPro-Medium', sans-serif;
$font-primary-regular	: 'Conv_DINPro-Regular', sans-serif;

$font-secondary			: 'Conv_Gotham-Light', sans-serif;
$font-secondary-book	: 'Conv_Gotham-Book', sans-serif;
$font-secondary-bold	: 'Conv_Gotham-Bold', sans-serif;

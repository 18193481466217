.resourcetiles {
	.tiles {
		@include break-lg {		
			overflow:hidden;
			position:relative;
			top:0px;
			text-align:center;
			display:-webkit-flex;
			display:-webkit-box;
			display:-moz-flex;
			display:-moz-box;
			display:-ms-flexbox;
			display:flex;
			/*
			flex-direction:row;
			flex-wrap: wrap;
			*/
			/* Then we define the flow direction and if we allow the items to wrap 
			* Remember this is the same as:
			* flex-direction: row;
			* flex-wrap: wrap;
			*/
			flex-flow: row wrap;
    		-webkit-flex-flow: row wrap; /* Safari 6.1+ */

			/* Then we define how is distributed the remaining space */
			justify-content: center;
		}
	}
}

.resource-item {
	@extend .col-lg-4;
	@extend .col-md-6;
	@extend .col-sm-6;
	max-width: 380px;

	@include break-lg(true) {
		&:nth-child(3n+1){
			clear: both;
		}
	}

	@include break-lg {
		float: none;
    	width: 380px;
	}

	@include break-sm {
		&:last-child .tile {
			margin-bottom: 0;
		}
	}

	@include break-xs {
		width: 100%;
	}
	
	> .tile {
		margin-bottom: 40px;
	}
}



.srcsrcpnl-filterwrap {
	margin: auto auto 20px auto !important;
	width: inherit !important;

	@media only screen and (max-width: 1030px) {
		@include clearfix;
		display: block !important;
	}


	@include break-sm {
		.text-right {
			text-align: left !important;
		}
	}
}

.srcsrcpnl-filtercol {
	@media only screen and (max-width: 1030px) {
		display: block !important;
		padding-bottom: 10px;
		text-align: center;
		width: 100%;
	}
}

.srcsrcpnl-filtercol-label {	
	@media only screen and (min-width: 1031px) {
	    padding-right: 12px;
	    white-space: nowrap;
	}
}

.srcsrcpnl-filtercol-dropdown {
	@media only screen and (min-width: 1031px) {
		width: 70%;
	}
	@media only screen and (max-width: 1030px) {
		max-width: 350px;
		margin: auto;
	}
	> .row {
		margin-left: -6px;
		margin-right: -6px;
	}

	.srcsrcpnl-filtercol {
		padding-left: 6px;
		padding-right: 6px;
	}
}

.srcsrcpnl-filtercol-trigger {
	@media only screen and (min-width: 1031px) {
		padding-left: 12px;
		width: 100px;
	}
	
}

.resource-btnwrap {
	clear: both;
	padding-top: 60px;

	@include break-sm {
		padding-top: 40px;
	}

	@include break-xs {
		padding-top: 20px;
	}
}

.srcsrcpnl-select-subject {

	&.bootstrap-select.btn-group {

		.dropdown-menu.inner {
			max-height: none !important;
		}
	}
}


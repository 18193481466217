//.imagegalleryslider {
//	padding: 20px 50px 40px 50px;
//	position: relative;
//	margin: auto;
//	max-width: 574px;
//
//	&.holderloader {
//	    overflow: hidden;
//	    max-height: 712px;
//	}
//
//	@include break-sm {
//		padding: 20px 10px 40px 10px;
//	}
//
//
//	.slick-list {
//		margin: 2px;
//	}
//}
//
//.imggalslid-content {
//	.slick-dots {
//		text-align: center;
//		padding: 10px;
//		bottom: 0;
//
//		li {
//			button {
//				@include box-shadow(none);
//				background-color: $gray-dark;;
//				height: 16px;
//				width: 16px;
//			}
//
//			&.slick-active button{
//				background-color: $gray-darker;
//				border: none;
//				height: 14px;
//				width: 14px;
//			}
//		}
//	}
//}
//
//.imggalslid-item {
//	@include transition(opacity ease .3s);
//	opacity: 1;
//	position: relative;
//	text-align: center;
//	height: auto;
//
//	.holderloader & {
//		opacity: 0;
//	}
//
//	img {
//		position: relative;
//		height: auto;
//		margin: auto;
//		width: auto;
//		max-height: 100%;
//		max-width: 100%;
//		z-index: 0;
//	}
//}
//
//
//.imggalslid-button {
//	@include transition(color ease .3s);
//	@include extend-icomoon;
//	cursor: pointer;
//	display: block;
//	font-size: 4rem;
//	height: 60px;
//	overflow: hidden;
//	padding-top: 12px;
//	position: absolute;
//	top: 50%;
//	margin-top: -34px;
//	text-align: center;
//	width: 40px;
//	z-index: 2;
//
//	&.slick-disabled {
//		color: $gray-dark;
//		cursor: default;
//	}
//}
//
//
//.imggalslid-prev {
//    @extend .icon-navigation-left;
//    left: -43px;
//
//    @include break-sm {
//    	left: -8px;
//    }
//
//}
//
//.imggalslid-next {
//	@extend .icon-navigation-right;
//	right: -43px;
//
//    @include break-sm {
//    	right: -8px;
//    }
//}
//
//
//.infotextitalic {
//	font-size: 1.6rem !important;
//	font-style: italic;
//	position: relative;
//	text-align: center;
//
//	.imagegalleryslider + & {
//		margin-top: -34px;
//		margin-bottom: 35px;
//	}
//
//	.slick-slide > & {
//		background-color: RGBA(0,0,0,.5);
//		color: #fff;
//		display: block;
//		line-height: 1.4em;
//		margin: 0;
//		padding: 10px;
//		position: absolute;
//		z-index: 10;
//		bottom: 0;
//
//		@include break-sm {
//			padding: 5px;
//		}
//	}
//}


.imagegalleryslider {

	}

	.swiper-root-container {
		padding-bottom: 20px;
		position: relative;
	}

	.swiper-container {
		display: block;
	}

	.imggalslid-center-gallery {
		img {
			margin: 0 auto;
			max-width: 100%;
			width: auto;
		}
	}

	.swiper-button {
		@include transition(color ease .3s);
		@include extend-icomoon;
		background: none !important;
		cursor: pointer;
		display: block;
		font-size: 4rem;
		height: 58px !important;
		overflow: hidden;
		padding-top: 10px;
		position: absolute;
		top: 50%;
		margin-top: -34px !important;
		text-align: center;
		width: 40px !important;
		z-index: 2;

		&.slick-disabled {
			color: lighten($gray-dark, 40%);
			cursor: default;
		}
	}

	.swiper-button-prev {
		@extend .icon-navigation-left;
		margin-left: -40px;

		@include break-md {
			color: #dde6fd;
			margin-left: -8px;
		}

	}

	.swiper-button-next {
		@extend .icon-navigation-right;
		background: none;
		margin-right: -40px;

		@include break-md {
			color: #dde6fd;
			margin-right: -8px;
		}
	}

	.swiper-figcaption {
		font-size: 1.2rem;
		/*
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.8);
        color: #fff;
        */
		font-style: italic;
		height: auto;
		line-height: 1.4em;
		padding: 10px;
		text-align: left;
		margin: auto;
		text-align: center;
		width: 100%;
		@include break-sm {
			font-size: 1.4rem;
		}
	}

	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		display: inline-block;
		border-radius: 100%;
		background: #000;
		opacity: .2;
		margin: 0 5px;

		&-active {
			background: #000 !important;
		}
	}

	.swiper-pagination {
		margin: 0 auto;
		text-align: center;
		width: 100%;
	}
//}
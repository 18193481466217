/*######################################################
	checkbox
#######################################################*/
.checkbox {
	label {
		padding-left: 32px;

		.icon-checkbox:before {
			background-color: #fff;
			border: solid 1px #ccc;
			content: "";
			color: $gray-darker;
			cursor: pointer;
			display: block;
			font-size: 12px;
			font-weight: bold;
			height: 20px;
			left: 0;
			padding-top: 3px;
			position: absolute;
			text-align: center;
			top: 6px;
			visibility: visible;
			width: 20px;

			.error & {
				border-color: $brand-danger;
			}

		}

		
	}

	.error & {
		label {
			margin-bottom: 0;
		}

		.form-message {
			margin-bottom: 20px;
		}
	}		

	input[type="checkbox"] {
		margin-left: 0;
		visibility: hidden;

		&:checked ~ .icon-checkbox:before{
			@include extend-icomoon;
			@extend .icon-tickmark;
		}
	}

	.label-caption {
		color: lighten($gray-darker, 40%);
		font-family: $font-primary-bold;	
	}

	input[type=checkbox]:checked ~ .label-caption {
		color: $gray-darker;
	}	
}


.checkbox {
	font-size: 1.8rem;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: 0;

	label {
		font-weight: 600;
		padding-bottom: 4px;
		padding-top: 4px;
		position: relative;

		a {
			text-decoration: underline;
		}
	}
}


/* checkbox group */
.checkboxgroup {
	display: table;
	table-layout: auto;
	margin: auto;

	.checkbox label {
		display: block;
		float: left;
		padding: 0;
		text-align: center;
		min-width: 20px;

	}

	.label-caption {
		@include box-shadow(0 1px 1px 0 rgba(0,0,0,0.1));
		background-color: #fff;
		color: $gray-darker;
		display: block;
		font-family: $font-primary-bold;
		font-size: 1.4rem;
		height: 50px;
		line-height: 1.4em;
		margin-left: 1px;
		margin-bottom: 1px;
		padding: 15px 0;
		width: 50px;
	}

	input[type=checkbox]:checked ~ .label-caption {
		@include box-shadow(inset 0 1px 1px 0 rgba(0,0,0,0.1));
		background-color: $gray-dark;
		color: $gray-base;
	}	
}

.cbg-lbl {
	display: table-cell;
	font-family: $font-primary-bold;
    font-size: 1.4rem;
    vertical-align: top;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.cbg-btn {
	@include box-shadow(0 1px 1px 0 rgba(0,0,0,0.1) !important);
	@include transition(none);
	border-collapse: separate;
	color: $gray-darker;
	background-color: #fff;
	border: none;
	display: table-cell;
	font-family: $font-primary-bold;
    font-size: 1.4rem;
    line-height: 1em;
    height: 50px;
    vertical-align: middle;
    padding-left: 0;
    padding-right: 0;
    min-width: 114px;

    &:hover {
    	background-color: white !important;
		color: $gray-darker;
    }

    &:focus,
    &:active,
    &.active {
		@include box-shadow(0 1px 1px 0 rgba(0,0,0,0.1) !important);
    	background-color: #fff;
		color: $gray-darker;
    }

    &.selected {
    	@include box-shadow(inset 0 1px 1px 0 rgba(0, 0, 0, 0.1) !important);
    	background-color: $gray-dark !important;
    	color: $gray-base;
    }
}

.cbg-cbx {
	@include make-row;
	display: table-cell;
	vertical-align: top;
}


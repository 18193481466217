/*######################################################
	heading
#######################################################*/
.h-lg {
	font-size: 3.6rem;
	font-weight: bold;
	line-height: normal;
}

.h-md {
	font-size: 2.8rem;
	font-weight: bold;
	line-height: 61px;
}

.h-sm {
	font-size: 2rem;
	font-weight: bold;
	line-height: 32px;
}

.h-xs {
	font-size: 1.6rem;
	font-weight: bold;
	line-height: normal;
}

.h-xxs {
	font-size: 1.4rem;
	font-weight: 500;
	line-height: normal;
}
.newseventstiles {
	margin: auto;
	max-width: 650px;

	.tl-title {
		max-width: inherit;

		&:before {
			display: none;
		}
	}
}

.newseventsfilter {
	.srchpnl-filterwrap {
		@include break-md {
			max-width: 400px;
		}
	}

	.bootstrap-select {
		width: 100%;
		@include break-md (true) {
			width: 200px;
		}
	}

	.srchpnl-filtercol {
		@include break-md {
			padding-bottom: 10px;
		}
	}
}


.nwsfltr{
	&-btn-clear {
		color: rgba(92, 92, 96, 0.6);
		font-size: 1.2rem;

		&:active,
		&:hover,
		&:focus {
			color: lighten($gray-base, 50%);
			text-decoration: none;
		}
	}

	&-upcoming-label {
		
	}
}


.tl-empty-message {
	font-family: $font-secondary-book;
    font-size: 1.8rem;
    margin: auto;
    text-align: center;
    padding: 100px 0;
}


.tl-empty-message--noPad {
	font-family: $font-secondary-book;
    font-size: 1.8rem;
    margin: auto;
    text-align: center;
    padding: 0;
}

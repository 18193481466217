.dd-mobile-wrap {
	position: relative;
	z-index: 100;
}

.dd-mobile-button {
	display: block;
	font-size: 1.8rem;
    background-color: #fff;
    border: none;
    outline: 0 none;
    padding: 20px 30px 20px 20px;
    position: relative;
	text-align: left;
	width: 100%;

	@include box-shadow(0 2px 2px -1px rgba(0, 0, 0, 0.1));
	@include break-md(true) {
		display: none;
	}

	.is-ddm-open > & {
		background-color: $gray-darker;
		color: #fff;
	}

	&:before {
		@include transition(all 0.2s linear);
		@include extend-icomoon;
		@extend .icon-dropdown;
		color: $gray-dark;
		display: block;
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -8px;

		.is-ddm-open > & {
			@include transform(rotate(180deg));
			color: #fff;
		}
	}


	span {		
		display: inline;
		font-size: 1em;

		~ span {
			padding-left: 5px;
		}
	}
}

.dd-mobile {
	@include break-md {
		@include box-shadow(0 2px 2px -1px rgba(0, 0, 0, 0.1));
		@include transition(all 0.2s linear);
		@include transform(scale(1, 0));
		@include transform-origin(top);
		@include animation-fill-mode(forwards);

		background-color: #fff;
		position: absolute;
		overflow: hidden;
		opacity: 0;
		top: 100%;
		left: 0;
		width: 100%;

		.is-ddm-open > & {
			@include transform(scale(1, 1));
			opacity: 1;
		}

		li {
			padding: 0 !important;

			&.active a {
				background-color: $gray;
			}

			a {
				color: $gray-darker !important;
				display: block;
				padding: 10px 20px;

				.sdpnl-navlinklabel {
					font-size: 1.6rem;
				}

				.sdpnl-navlinkdesc {
					font-size: 1.4rem;
				}

				&:hover {
					background-color: $gray;
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
	}

	
}
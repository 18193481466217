.breadcrumbspanel {
	padding: 35px 0;
	position: relative;
	margin-bottom: -90px;

	+ .page-section:first-of-type > .section-inner {
		padding-top: 80px;
	}
}

.breadcrumb {
	background-color: transparent;
	font-family: $font-secondary-bold;
	font-size: 1.3rem;
	margin: 0;
	padding: 0;

	.active {
		color: $gray-darker; 
	}

	> li + li:before {
		color: $gray-darker; 		
	}

	a {
		color: $gray-darker;
	}
}
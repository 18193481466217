/*######################################################
	fonts
#######################################################*/
@font-face {
	font-family: 'Conv_DINPro-Black';
	src: url('../Fonts/DINPro-Black/DINPro-Black.eot');
	src: local('☺'), url('../Fonts/DINPro-Black/DINPro-Black.woff') format('woff'), url('../Fonts/DINPro-Black/DINPro-Black.ttf') format('truetype'), url('../Fonts/DINPro-Black/DINPro-Black.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Bold';
	src: url('../Fonts/DINPro-Bold/DINPro-Bold.eot');
	src: local('☺'), url('../Fonts/DINPro-Bold/DINPro-Bold.woff') format('woff'), url('../Fonts/DINPro-Bold/DINPro-Bold.ttf') format('truetype'), url('../Fonts/DINPro-Bold/DINPro-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Light';
	src: url('../Fonts/DINPro-Light/DINPro-Light.eot');
	src: local('☺'), url('../Fonts/DINPro-Light/DINPro-Light.woff') format('woff'), url('../Fonts/DINPro-Light/DINPro-Light.ttf') format('truetype'), url('../Fonts/DINPro-Light/DINPro-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Medium';
	src: url('../Fonts/DINPro-Medium/DINPro-Medium.eot');
	src: local('☺'), url('../Fonts/DINPro-Medium/DINPro-Medium.woff') format('woff'), url('../Fonts/DINPro-Medium/DINPro-Medium.ttf') format('truetype'), url('../Fonts/DINPro-Medium/DINPro-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Regular';
	src: url('../Fonts/DINPro-Regular/DINPro-Regular.eot');
	src: local('☺'), url('../Fonts/DINPro-Regular/DINPro-Regular.woff') format('woff'), url('../Fonts/DINPro-Regular/DINPro-Regular.ttf') format('truetype'), url('../Fonts/DINPro-Regular/DINPro-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Gotham-Bold';
	src: url('../Fonts/Gotham-Bold/Gotham-Bold.eot');
	src: local('☺'), url('../Fonts/Gotham-Bold/Gotham-Bold.woff') format('woff'), url('../Fonts/Gotham-Bold/Gotham-Bold.ttf') format('truetype'), url('../Fonts/Gotham-Bold/Gotham-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Gotham-Book';
	src: url('../Fonts/Gotham-Book/Gotham-Book.eot');
	src: local('☺'), url('../Fonts/Gotham-Book/Gotham-Book.woff') format('woff'), url('../Fonts/Gotham-Book/Gotham-Book.ttf') format('truetype'), url('../Fonts/Gotham-Book/Gotham-Book.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Gotham-Light';
	src: url('../Fonts/Gotham-Light/Gotham-Light.eot');
	src: local('☺'), url('../Fonts/Gotham-Light/Gotham-Light.woff') format('woff'), url('../Fonts/Gotham-Light/Gotham-Light.ttf') format('truetype'), url('../Fonts/Gotham-Light/Gotham-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

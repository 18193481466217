/*######################################################
	footer
#######################################################*/
footer {
	display: block;
	background-color: $gray-base;
	padding: 90px 0 40px 0;
	width: 100%;

	@include break-sm {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.ftr-row {	
	border-collapse: collapse;
	display: table;
	margin: 0;
	table-layout: fixed;
	width: 100%;

	@include break-sm {
		display: block;
		padding-bottom: 20px;
	}
}

.ftr-col {
	@include break-sm(true) {
		display: table-cell;
		float: none;
		vertical-align: middle;
	}

	@include break-sm {
		@include clearfix;
		display: block;
		padding-bottom: 10px;
		padding-top: 10px;
		text-align: center;
	}
}

.ftr-logo-wrap {
	@include clearfix;
	@include break-sm {
		text-align: center;
	}

	> * {
		&:not(:first-child) {
			margin-left: 4.5em;
		}

    @include break-xs {
      margin-bottom: 10px;

      &:not(:first-child) {
			  margin-left: 0;
		  }
    }
	}

	/*a {
		display: inline-block;
	}*/

	img {
		height: 55px;
	}
}

.ftr-awi-logo {
	@include background-retina('../Images/awi-white-logo.svg', '../Images/awi-white-logo.svg');
	display: block;
	float: left;
	height: 55px;
  max-width: 150px;
	width: 100%;

	@include break-sm {
		display: inline-block;
		float: none;
		height: 55px;
		margin: 10px;
		width: 130px;
	}	

}

.ftr-twc-logo {
	@include background-retina('../Images/twc-white-logo.svg', '../Images/twc-white-logo.svg');
	display: block;
	float: left;
	height: 55px;
	margin-left: 56px;
  max-width: 153px;
	width: 100%;

	@include break-md {
		margin-left: 10px;
	}

	@include break-sm {
		display: inline-block;
		float: none;
		height: 55px;
		margin: 10px;
		width: 153px;
	}
}

.ftr-about {
	font-family: $font-secondary;

	@include break-sm {
		font-size: 1.4rem;
	}
}

.ftr-menu {
	font-family: $font-secondary;
	display: block;
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: right;

	@include break-md {
		display: table;
		table-layout: auto;
		width: 100%;
	}

	@include break-sm {
		display: block;
		text-align: center;
	}

	li {
		display: inline-block;
		padding: 2px 20px;

		&:last-child {
			padding-right: 0;
		}

		@include break-md {
			display: table-cell;
			padding: 2px;
		}

		@include break-sm {
			display: block;
			padding: 0;
			text-align: center;
		}

		&.active > a {
			color: rgba(255, 255, 255, 1);
		}
	}

	a {
		@include transition(color linear .3s);

		&:hover {
			color: rgba(255, 255, 255, 1);
		}
	}

	a, span {		
		color: rgba(125, 125, 125, 1);
		display: inline-block;
		font-size: 1.6em;
		outline: 0 none;
		padding: 10px 0;

		&:hover,
		&:focus,
		&:active {
			outline: 0 none;
			text-decoration: none;
		}

		@include break-sm {
			display: block;
			font-size: 1.4rem;
			font-weight: 100;
			padding: 6px 0;
		}
	}
}

.ftr-copyrights {
	padding-top: 10px;
	color: rgba(125, 125, 125, 1);
	float: right;
	font-size: 1.2rem;

	@include break-sm {
		display: block;
		float: none;
		text-align: center;
	}
}

.ftr-logo-link {
  display: inline-block;
  height: 55px;

  @include break-xs {
    display: block;
  }
}

.ftr-logo-img {
  height: 100%;
}
/*######################################################
  side panel
#######################################################*/
.sidepanel {
	border-bottom: 1px solid $border-color;
	padding-bottom: 15px;
	margin-bottom: 30px;

	&:last-child {
		border: none;
		margin-bottom: 0;
	}

	.page-col > *:not(.sidepanel) + & {
		padding-top: 30px;
	}
}

.sdpnl-content {
	.wysiwyg-content {
		font-size: 1.4rem;
		color: $gray-darker;
		line-height: 1.8em;
		padding-bottom: 10px;
	}

	+ .sdpnl-content {
		padding-top: 20px;
	}
}

.sdpnl-title {
	color: $gray-base;
	font-family: $font-primary-bold;
	font-size: 1.6rem;
	line-height: 1;
	margin: 0;
	margin-bottom: 16px;
    text-transform: uppercase;

	.is-pgcol-sidenav & {
		font-family: $font-secondary-bold;
	}

	a {
		color: $gray-base;
	}
}

.sdpnl-label {
	color: $gray-base;
	font-family: $font-secondary-bold;
	font-size: 1.4rem;
	line-height: 1;
	margin: 0;
	padding-bottom: 3px;

	a {
		color: $gray-base;		
	}
}

.sdpnl-caption {
	color: $gray-base;
	font-family: $font-secondary-book;
	font-size: 1.4rem;
}

.sdpnl-thumblist-wrap {
	@include clearfix;
}

.sdpnl-colwrap {
	.sdpnl-thumblist-wrap & {
        &:last-child {
            .sdpnl-colcell {
                padding-bottom: 0;
            }
        }
    }
}

.sdpnl-colcell {
	.sdpnl-thumblist-wrap & {
		border-top: 1px solid $border-color;
		padding-top: 15px;
		padding-bottom: 20px;
	}
}

.sdpnl-col-thumb {
	width: 60px;

	.sdpnl-wrap-videoicon {	
		background-color: $gray-darker;
		display: block;
		height: auto;
		position: relative;
		width: 100%;

		&:before {
			@include extend-icomoon;
			@extend .icon-video-play;
			color: #fff;
			font-size: 22px;
			font-weight: normal;
			left: 50%;
			margin-top: -11px;
			margin-left: -11px;	
			position: absolute;
			top: 50%;
			z-index: 5;
		}
	}

	img {
		@extend .img-responsive;
	}
}

.sdpnl-col-text {
	padding-left: 10px;
}

.sdpnl-linkicon {
	display: block;
	color: $gray-base;
	font-family: $font-secondary-book;
	font-size: 1rem;
	margin-top: 10px;

	&[class*="link-"] {
		position: relative;
		padding-left: 20px;
		min-height: 17px;

		&:before {
			@include extend-icomoon;
			font-size: 1.6rem;
			position: absolute;
			left: 0;
			top: 1;
		}
	}

	&.link-pdf:before {
		@extend .icon-pdf-square;
	}

	&.link-video:before {
		@extend .icon-video;
	}
}

.sdpnl-navlink {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: block;        
	}

	a {
		@include transition(all linear .2s);
		display: block;
		color: $gray-base;
		padding-top: 8px;
		padding-bottom: 8px;
		min-height: 48px;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }

        &:hover {
        	background-color: $gray-darker;
            color: #fff;
            padding-left: 10px;
            padding-right: 10px;
        }

       
		&.sdpnl-navlink-overview {
			@include break-md(true) {
				padding-top: 18px;
				padding-bottom: 17px;
            }

            @include break-md {
            	padding: 20px;
            }
        }
	}

	li.active {
    	@include break-md(true) {
            a {
            	background-color: $gray-darker;
                color: #fff;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

.sdpnl-navlinklabel {
	display: block;
	font-family: $font-secondary-bold;
	font-size: 1.4rem;
}

.sdpnl-navlinkdesc {
	display: block;
	font-family: $font-secondary-book;
	font-size: 1.2rem;
}

//AWIS-40

.sdpnl-content:not(.resource-intro) {

	.wysiwyg-content {
		h3 {
			@extend .sdpnl-title;
		}
		p {
				line-height: 1em !important;

				color: $gray-base;
				font-family: $font-primary-bold;
				font-size: 1.4rem;
				display: block;
				margin: 0 !important;
				padding-bottom: 12px !important;

				span {
					color: $gray-base;
					font-family: $font-primary-bold;
					font-size: 1.4rem;
					line-height: 1em;
					display: block;
				}

		}
	}


}